export class WeekStartEnum {
  static SUNDAY = 0;

  static MONDAY = 1;

  static TUESDAY = 2;

  static WEDNESDAY = 3;

  static THURSDAY = 4;

  static FRIDAY = 5;

  static SATURDAY = 6;
}
