import { DateTime } from 'luxon';

import { SETTINGS_SET, SETTINGS_SET_TIMEZONE, SETTINGS_SET_WEEKSTART } from '../actions/actionTypes';
import { WeekStartEnum } from '../../containers/models/WeekStartEnum';

const initialState = {
  weekStart: WeekStartEnum.MONDAY.toString(),
  timezone: DateTime.local().zoneName,
  schedule: [],
  timezones: [],
  isUserDefined: null
};

const reducer = (state = initialState, action) => {
  if (action.type === SETTINGS_SET && action.settings) {
    return { ...action.settings, schedule: action.schedule, timezones: action.timezones, isUserDefined: true };
  }

  if (action.type === SETTINGS_SET && !action.settings) {
    return { ...state, schedule: action.schedule, timezones: action.timezones, isUserDefined: false };
  }

  if (action.type === SETTINGS_SET_TIMEZONE) {
    return { ...state, timezone: action.newTz };
  }

  if (action.type === SETTINGS_SET_WEEKSTART) {
    return { ...state, weekStart: action.newWeekStart };
  }

  return state;
};

export default reducer;
