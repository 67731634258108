import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import { checkAuthState } from './store/actions/auth';

const Layout = lazy(() => import('./components/Layout/Layout'));
const AuthLayout = lazy(() => import('./components/AuthLayout/AuthLayout'));
const AuthView = lazy(() => import('./containers/AuthView/AuthView'));
const SharedCalendarView = lazy(() => import('./containers/SharedCalendarView/SharedCalendarView'));
const OwnCalendarView = lazy(() => import('./containers/OwnCalendarView/OwnCalendarView'));
const SettingsView = lazy(() => import('./containers/SettingsView/SettingsView'));


class App extends Component {
  componentDidMount() {
    this.props.onTryAuth();
  }

  render() {
    switch (this.props.isAuthenticated) {
      case -1:
        return <Switch>
          <Route path="/calendar/:token" exact>
            <Suspense fallback={ Loader }>
              <Layout>
                <SharedCalendarView/>
              </Layout>
            </Suspense>
          </Route>
          <Route path="/" exact>
            <Suspense fallback={ Loader }>
              <AuthLayout>
                <AuthView/>
              </AuthLayout>
            </Suspense>
          </Route>
          <Redirect to="/"/>
        </Switch>;
      case 1:
        if (this.props.settings.isUserDefined === null) {
          return <Loader/>;
        }

        if (this.props.settings.isUserDefined === false && this.props.location.pathname !== '/settings') {
          return <Redirect to="/settings"/>;
        }

        return <Switch>
          <Route path="/settings">
            <Suspense fallback={ Loader }>
              <Layout>
                <SettingsView/>
              </Layout>
            </Suspense>
          </Route>
          <Route path="/calendar/:token" exact>
            <Suspense fallback={ Loader }>
              <Layout>
                <SharedCalendarView/>
              </Layout>
            </Suspense>
          </Route>
          <Route path="/" exact>
            <Suspense fallback={ Loader }>
              <Layout>
                <OwnCalendarView/>
              </Layout>
            </Suspense>
          </Route>
        </Switch>;
      default:
        return <Loader/>;
    }
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token === null ? 0 : (state.auth.token === false ? -1 : 1),
    settings: state.settings
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAuth: () => dispatch(checkAuthState())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
