import React, { Component } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import PropTypes from 'prop-types';

import classes from './Loader.module.css';
import Backdrop from '../Backdrop/Backdrop';
import Spinner from '../Spinner/Spinner';

class Loader extends Component {
  render() {
    let loader;
    if (this.props.progress >= 0) {
      loader =
        <div className={ classes.progressBarContainer }>
          <div className={ classes.progressBarLabel }>{ this.props.progress + '%' }</div>
          <ProgressBar className={ classes.progressBar } now={ this.props.progress }/>
        </div>;
    } else {
      loader = <Spinner/>;
    }

    return <div className={ classes.loaderContainer }>
      <Backdrop/>
      { loader }
    </div>
  }
}

Loader.propTypes = {
  processIds: PropTypes.string,
  onProgressDone: PropTypes.func
};

export default Loader;
