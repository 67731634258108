import { NOTIFICATION_ADD, NOTIFICATION_CLEAR, NOTIFICATION_DROP } from './actionTypes';

export const notificationAdd = notification => {
  return {
    type: NOTIFICATION_ADD,
    notification
  };
};

export const notificationDrop = notification => {
  return {
    type: NOTIFICATION_DROP,
    notification
  };
};

export const notificationsClear = () => {
  return {
    type: NOTIFICATION_CLEAR
  };
};
