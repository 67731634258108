import { AUTH_FAIL, AUTH_LOGOUT, AUTH_START, AUTH_SUCCESS } from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  token: null,
  error: null,
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_START:
      return updateObject(state, { error: null, loading: true, token: false });
    case AUTH_SUCCESS:
      return updateObject(state, { token: action.token, error: null, loading: true });
    case AUTH_FAIL:
      return updateObject(state, { error: action.error, loading: false, token: false });
    case AUTH_LOGOUT:
      return updateObject(state, { error: null, loading: false, token: false });
    default:
      return state;
  }
};

export default reducer;
