import axios from '../../services/axios';

import {
  TOUR_PASSED_FOR_OWN_CALENDAR,
  TOUR_PASSED_FOR_SETTINGS, TOUR_PASSED_FOR_SHARED_CALENDAR,
  TOUR_PASSED_SET_INFO
} from './actionTypes';

export const tourPassedForSettings = () => {
  return async dispatch => {
    try {
      await axios.put('api/tours/settings');
      dispatch({ type: TOUR_PASSED_FOR_SETTINGS });
    } catch (e) {
      // TODO: Failed to save tours
    }
  };
};

export const tourPassedForOwnCalendar = () => {
  return async dispatch => {
    try {
      await axios.put('api/tours/own-calendar');
      dispatch({ type: TOUR_PASSED_FOR_OWN_CALENDAR });
    } catch (e) {
      // TODO: Failed to save tours
    }
  };
};

export const tourPassedForSharedCalendar = () => {
  return async dispatch => {
    try {
      await axios.put('api/tours/shared-calendar');
      dispatch({ type: TOUR_PASSED_FOR_SHARED_CALENDAR });
    } catch (e) {
      // TODO: Failed to save tours
    }
  };
};

export const toursFetch = () => {
  return async dispatch => {
    try {
      const response = await axios.get('api/tours');

      dispatch({
        type: TOUR_PASSED_SET_INFO,
        tours: response.data
      });
    } catch (e) {
      // TODO: can't fetch tours info
    }
  };
};
