import { AUTH_FAIL, AUTH_LOGOUT, AUTH_START, AUTH_SUCCESS } from './actionTypes';
import axios from '../../services/axios';

import { notificationAdd } from './notification';
import { Notification, NotificationSeverity } from '../../components/Notifications/Notification';
import { settingsFetch } from './settings';
import { toursFetch } from './tours';
import { POLLING_EVENTS_URL } from '../../constants';
import PollingWorkerFactory from '../../services/PollingWorkerFactory';

let autoLogoutTimer = null;

const checkExpiration = async (token, dispatch) => {
  const expiresAtMs = JSON.parse(atob(token.split('.')[1])).exp * 1000;
  if (Date.now() >= expiresAtMs - 5000) {
    dispatch(authLogout());
  } else {
    dispatch(settingsFetch());
    dispatch(toursFetch());
    dispatch(authSuccess(token));
    dispatch(checkAuthTimeout(expiresAtMs - Date.now()));
  }
};

export const authStart = () => {
  return {
    type: AUTH_START
  };
};

export const authSuccess = token => {
  return {
    type: AUTH_SUCCESS,
    token
  };
};

export const authFail = error => {
  return {
    type: AUTH_FAIL,
    error
  };
};

export const authLogout = () => {
  if (autoLogoutTimer) {
    clearTimeout(autoLogoutTimer);
  }

  window.localStorage.removeItem('token');
  window.localStorage.removeItem('refreshToken');
  window.localStorage.removeItem('refreshTokenExp');
  PollingWorkerFactory.instance(POLLING_EVENTS_URL).unregister();

  return {
    type: AUTH_LOGOUT
  };
};

export const checkAuthTimeout = expiresInMs => {
  return dispatch => {
    if (autoLogoutTimer) {
      clearTimeout(autoLogoutTimer);
    }

    autoLogoutTimer = setTimeout(() => {
      dispatch(authLogout());
    }, expiresInMs)
  };
};

export const checkAuthState = () => {
  return async (dispatch, getState) => {
    let token = window.localStorage.getItem('token') || null;
    const tokenState = getState().auth.token;
    if (!token && !tokenState) {
      dispatch(authLogout());
      return;
    }

    if ((!token && tokenState) || token.split('.').length < 3) {
      dispatch(authLogout());
      return;
    }

    await checkExpiration(token, dispatch)
  };
};

export const auth = ssoToken => {
  return async dispatch => {
    try {
      const response = await axios.post('api/auth/recognize', { sso: ssoToken });
      window.localStorage.setItem('token', response.data.accessToken);
      window.localStorage.setItem('refreshToken', response.data.refreshToken);
      window.localStorage.setItem('refreshTokenExp', response.data.refreshTokenExp);
      await checkExpiration(response.data.accessToken, dispatch)
    } catch (e) {
      dispatch(authFail(e));
      let errorMessage = 'Failed to sign you in, please try again later.';
      if (e.response && e.response.data && e.response.data.error) {
        errorMessage = e.response.data.error;
      }
      dispatch(notificationAdd(new Notification(NotificationSeverity.ERROR, errorMessage, 5)));
    }
  };
};
