import {
  TOUR_PASSED_FOR_OWN_CALENDAR,
  TOUR_PASSED_FOR_SETTINGS,
  TOUR_PASSED_FOR_SHARED_CALENDAR,
  TOUR_PASSED_SET_INFO
} from '../actions/actionTypes';

const initialState = {
  isSettingsTourPassed: true,
  isOwnCalendarTourPassed: true,
  isSharedCalendarTourPassed: true
};

const reducer = (state = initialState, action) => {
  if (action.type === TOUR_PASSED_FOR_SETTINGS) {
    return { ...state, isSettingsTourPassed: true };
  }

  if (action.type === TOUR_PASSED_FOR_OWN_CALENDAR) {
    return { ...state, isOwnCalendarTourPassed: true };
  }

  if (action.type === TOUR_PASSED_FOR_SHARED_CALENDAR) {
    return { ...state, isSharedCalendarTourPassed: true };
  }

  if (action.type === TOUR_PASSED_SET_INFO) {
    return { ...state, ...action.tours };
  }

  return state;
};

export default reducer;
