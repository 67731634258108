import { COMMENTS_ADD, COMMENTS_SET } from '../actions/actionTypes';

const initialState = {
  entries: []
};

const reducer = (state = initialState, action) => {
  if (action.type === COMMENTS_SET) {
    return { entries: action.entries };
  }

  if (action.type === COMMENTS_ADD) {
    const newState = { ...state };

    const newEntries = action.entries.filter(val => {
      const existingIndex = newState.entries.findIndex(current => current.id === val.id);
      if (existingIndex === -1) {
        return true;
      }

      newState.entries[existingIndex] = val;

      return false;
    });

    newState.entries = newState.entries.concat(newEntries).filter(val => val !== undefined);

    return newState;
  }

  return state;
};

export default reducer;
