import { NOTIFICATION_ADD, NOTIFICATION_CLEAR, NOTIFICATION_DROP } from '../actions/actionTypes';

const initialState = {
  notifications: []
};

const notificationDrop = (state, notificationToDrop) => {
  const newState = { ...state };
  const notificationIndex = newState.notifications.findIndex(notification => notification === notificationToDrop);

  if (notificationIndex === -1) {
    return state;
  }

  delete newState.notifications[notificationIndex];
  newState.notifications = newState.notifications.filter(_ => _ !== undefined);

  return newState;
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_ADD:
      let newState = { ...state };
      newState.notifications.forEach(notification => {
        if (notification.isSimilar(action.notification)) {
          newState = notificationDrop(newState, notification);
        }
      });

      const notifications = [ ...newState.notifications ];
      notifications.push(action.notification);

      newState.notifications = notifications;

      return newState;
    case NOTIFICATION_DROP:
      return notificationDrop(state, action.notification);
    case NOTIFICATION_CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};

export default notification;
