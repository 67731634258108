const PollingWorker = () => {
  function LongPolling() {
    this.isPollingRunning = false;
    this.pollingProcess = null;
    this.delayInterval = null;
    this.abortController = new AbortController();
    this.url = null;
    this.payload = {};
    this.headers = {};

    this.start = (url, payload, headers = {}, delaySec) => {
      if (this.isPollingRunning) {
        throw new Error("[LongPollingService] Polling is already running, stop it before running again.");
      }

      this.url = url;
      if (headers && typeof headers === 'object') {
        this.headers = headers;
      }

      this.headers['X-Requested-With'] = 'XMLHttpRequest';
      this.headers['Content-Type'] = 'application/json';

      this.isPollingRunning = true;
      this.payload = payload;

      if (delaySec) {
        this.delayInterval = setTimeout(() => {
          this.delayInterval = null;
          this.poll();
        }, delaySec * 1000);
      } else {
        this.poll();
      }
    };

    this.stop = () => {
      this.isPollingRunning = false;

      if (this.pollingProcess !== null) {
        this.pollingProcess = this.pollingProcess.finally(() => {
          this.pollingProcess = null;
        });

        this.abortController.abort();

        return this.pollingProcess;
      }

      if (this.delayInterval !== null) {
        clearTimeout(this.delayInterval);
      }

      return Promise.resolve(null);
    }

    this.isRunning = () => {
      return this.isPollingRunning;
    }

    this.poll = () => {
      if (!this.isPollingRunning) {
        return;
      }

      this.pollingProcess = this.performPollingRequest()
        .then(response => {
          if (!response.ok) {
            return Promise.reject(response);
          }

          return response.json();
        }).then(response => {
          postMessage(response.data);
          setTimeout(() => {
            this.payload.latestEntryId = response.latestEntryId;
            this.poll();
          }, 0);
        }).catch((e) => {
          if (e.name === "AbortError") {
            return Promise.reject(e);
          }

          if (401 === e.status) {
            this.stop();
          } else if (-1 === [504, 408, 520].indexOf(e.status)) {
            setTimeout(
              this.poll.bind(this),
              30 * 1000
            );
          } else {
            this.poll();
          }
        });
    }

    this.performPollingRequest = () => {
      return fetch(
        this.url,
        {
          method: 'POST',
          headers: this.headers,
          signal: this.abortController.signal,
          body: JSON.stringify(this.payload),
          mode: 'cors',
          credentials: 'omit'
        }
      );
    }
  }

  const LongPollingService = new LongPolling();
  let isStarted = false;
  // eslint-disable-next-line no-restricted-globals
  addEventListener('message', event => {
    switch (event.data.cmd) {
      case 'start':
        if (!isStarted) {
          LongPollingService.start(event.data.url, event.data.payload, event.data.headers);
          isStarted = true;
        }
        break;
      case 'stop':
        if (isStarted) {
          isStarted = false;
          LongPollingService.stop().then(() => {
            // eslint-disable-next-line no-restricted-globals
            close();
          }).catch(e => {
            console.log(e)
          });
        }
        break;
      default:
        break;
    }
  }, false);
};

export default PollingWorker;
