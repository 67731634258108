import axios from 'axios';

import { APP_API_HOST } from '../constants';
import { authLogout } from '../store/actions/auth';
import store from '../store/store';

const instance = axios.create({
  baseURL: APP_API_HOST
});

instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.common['Authorization'] = 'Bearer ' + token;
  }

  return config;
});

instance.interceptors.response.use(
  response => response,
  error => {
    if (401 === error.response.status) {
      store.dispatch(authLogout());
    } else {
      return Promise.reject(error);
    }
  }
);

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export default instance;
