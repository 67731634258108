import axios from '../../services/axios';
import { SETTINGS_SET, SETTINGS_SET_TIMEZONE, SETTINGS_SET_WEEKSTART } from './actionTypes';

export const settingsSave = schedule => {
  return async (dispatch, getState) => {
    dispatch(settingsSet({
      schedule,
      timezones: getState().settings.timezones,
      settings: {
        timezone: getState().settings.timezone,
        weekStart: getState().settings.weekStart
      }
    }));
  };
};

export const settingsFetch = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get('api/settings');
      const data = {
        schedule: response.data.schedule.map(item => ({
          dayOfWeek: item.week_day,
          start: item.start.slice(0, item.start.lastIndexOf(":")),
          end: item.end.slice(0, item.start.lastIndexOf(":"))
        })),
        timezones: response.data.timezones,
        settings: null
      };
      if (response.data.settings) {
        data.settings = {
          weekStart: +response.data.settings.week_start,
          timezone: response.data.settings.timezone
        };
      }
      dispatch(settingsSet(data));
    } catch (e) {
      // TODO: Failed to load settings
      console.error('Failed to load settings.')
    }
  };
};

export const settingsUpdateTimezone = newTz => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    if (token) {
      await axios.put('api/settings/update/timezone', { timezone: newTz });
    }

    return dispatch(settingsSetTimezone(newTz))
  };
};

export const settingsSetWeekStart = newWeekStart => {
  return {
    type: SETTINGS_SET_WEEKSTART,
    newWeekStart
  };
};

export const settingsSetTimezone = newTz => {
  return {
    type: SETTINGS_SET_TIMEZONE,
    newTz
  };
};

export const settingsSet = settings => {
  return {
    type: SETTINGS_SET,
    ...settings
  };
};
