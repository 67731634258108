export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const NOTIFICATION_ADD = 'NOTIFICATION_ADD';
export const NOTIFICATION_DROP = 'NOTIFICATION_DROP';
export const NOTIFICATION_CLEAR = 'NOTIFICATION_CLEAR';

export const SETTINGS_SET = 'SETTINGS_SET';
export const SETTINGS_SET_TIMEZONE = 'SETTINGS_SET_TIMEZONE';
export const SETTINGS_SET_WEEKSTART = 'SETTINGS_SET_WEEKSTART';

export const TOUR_PASSED_FOR_SETTINGS = 'TOUR_PASSED_FOR_SETTINGS';
export const TOUR_PASSED_FOR_OWN_CALENDAR = 'TOUR_PASSED_FOR_OWN_CALENDAR';
export const TOUR_PASSED_FOR_SHARED_CALENDAR = 'TOUR_PASSED_FOR_SHARED_CALENDAR';
export const TOUR_PASSED_SET_INFO = 'TOUR_PASSED_SET_INFO';

export const EVENT_BUS_SUBSCRIBE = 'EVENT_BUS_SUBSCRIBE';
export const EVENT_BUS_UNSUBSCRIBE = 'EVENT_BUS_UNSUBSCRIBE';

export const EVENTS_SET = 'EVENTS_SET';
export const EVENTS_ADD = 'EVENTS_ADD';

export const COMMENTS_ADD = 'COMMENTS_ADD';
export const COMMENTS_SET = 'COMMENTS_SET';
