import { APP_API_HOST } from '../constants';
import PollingWorker from './PollingWorker';

class PollingWorkerFactory {
  static instances = {};

  isRegistered = false;
  worker;
  apiUrl;

  constructor(apiUrl) {
    this.apiUrl = apiUrl;
  }

  static instance(apiUrl) {
    if (PollingWorkerFactory.instances[apiUrl]) {
      return PollingWorkerFactory.instances[apiUrl];
    }

    PollingWorkerFactory.instances[apiUrl] = new PollingWorkerFactory(apiUrl);

    return PollingWorkerFactory.instances[apiUrl];
  }

  register(handler, initialLatestEntryId, payload = {}, sharedToken = null) {
    if (this.isRegistered) {
      return;
    }

    if (!payload) {
      payload = {};
    }

    payload.latestEntryId = initialLatestEntryId;

    this.worker = new PollingWorker();
    this.worker.addEventListener('message', event => handler(event.data), false);

    const token = localStorage.getItem('token');
    const headers = {};
    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }

    if (!token && !sharedToken) {
      throw new Error('Anonymous polling try was restricted.');
    }

    this.worker.postMessage({
      cmd: 'start',
      url: APP_API_HOST + this.apiUrl + (token ? '' : '/' + sharedToken),
      payload,
      headers
    });

    this.isRegistered = true;

    return this.worker;
  }

  unregister() {
    if (!this.isRegistered) {
      return;
    }

    this.worker.postMessage({ cmd: 'stop' });
    this.worker.terminate();
    this.worker = null;
    this.isRegistered = false;
  }
}

export default PollingWorkerFactory;
