import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import notificationReducer from './reducers/notification';
import authReducer from './reducers/auth';
import settingsReducer from './reducers/settings';
import toursReducer from './reducers/tours';
import eventBusReducer from './reducers/eventBus';
import eventsReducer from './reducers/events';
import commentsReducer from './reducers/comments';
import { ENV_DEV } from '../env';

const rootReducer = combineReducers({
  notification: notificationReducer,
  auth: authReducer,
  settings: settingsReducer,
  tours: toursReducer,
  eventBus: eventBusReducer,
  events: eventsReducer,
  comments: commentsReducer
});

const composeEnhancers = process.env.REACT_APP_ENV === ENV_DEV
  ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)
  : compose;

export default createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
