import { ENV_DEV } from './env';

export let MAIN_APP;
export let APP_API_HOST;

switch (process.env.REACT_APP_ENV) {
  case ENV_DEV:
    MAIN_APP = 'https://roamingaudit.test';
    APP_API_HOST = 'https://api.coordinator.roamingaudit.test';
    break;
  default:
    MAIN_APP = 'https://secure.roamingaudit.com';
    APP_API_HOST = 'https://api.coordinator.roamingaudit.com';
    break;
}

export const POLLING_COMMENTS_URL = '/api/comments/polling'

export const POLLING_EVENTS_URL = '/api/events/polling'
